import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import RolexCategoryBtns from '../../components/preowned/RolexCategoryBtns'

// markup
const RolexExplorer = () => {
  const data = useStaticQuery(
    graphql`
      query queryRolexExplorer {
        products: allStrapiProduct(
          filter: { brand: { eq: "Rolex" }, model: { regex: "/Explorer/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Rolex Explorer Watches for Sale'}
      canonical={'/fine-watches/rolex/rolex-explorer/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Rolex Explorer Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/catalogsearch/result?q=explorer">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-rolex-explorer-header.png"
                alt="Pre-Owned Certified Used Rolex Explorer Watches Header"
                aria-label="Used Rolex Explorer Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              ROLEX EXPLORER: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED ROLEX WATCHES
            </h1>
            <h2>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED ROLEX EXPLORER WATCHES</h2>
            <p>
              <br />
              Pre-Owned Used Rolex Explorer Watch
              <br />
              <br />
              Launched in 1953 in honor of Sir Edmund Hillary and Tenzing Norgay’s successful ascent
              of Mount Everest, the Rolex Explorer is not only one of the brand’s oldest sports
              watch models but also one of the most understated. The Rolex Explorer features a
              water-resistant case, smooth bezel, time-only black dial, and three-link Oyster
              bracelet. The defining feature of the Explorer is a black dial with a trio of Arabic
              numerals at 3, 6, and 9.
              <br />
              <br />
              As its name suggests, the Explorer was developed as a Rolex watch for explorers and
              adventurers. Current versions look remarkably similar to vintage and pre-owned used
              Rolex Explorer watches, which illustrates the enduring appeal of this iconic
              timepiece. The popularity of secondhand Explorer watches has exploded in the pre-owned
              Rolex market over the last decade or so, with no signs of slowing down.
              <br />
              <br />
              <b>Buying and Selling Pre-Owned Used Rolex Explorer Watches</b>
              <br />
              <br />
              For most of its history, the Rolex Explorer watch for men was exclusively available in
              stainless steel. However, Rolex recently added a two-tone Explorer variant to its
              lineup. What’s more, the vast majority of used Rolex Explorer watches have 36mm cases;
              the only exception is the now-discontinued Explorer reference 214270 with a 39mm case.
              <br />
              <br />
              <b>Pre-Owned Used Rolex Explorer Watch References:</b>
              <br />
              <br />
              <ul>
                <li>Current Explorer ref. 124270: 36mm, steel </li>
                <li>Current Explorer ref. 124273: 36mm, two-tone steel and gold </li>
                <li>Discontinued Explorer ref. 214270 (2010 – 2021): 39mm, steel </li>
                <li>Discontinued Explorer ref. 114270 (2001 – 2010): 36mm, steel </li>
                <li>Discontinued Explorer ref. 14270 (1989 – 2001): 36mm, steel </li>
                <li>Vintage Explorer ref. 1016 (1963 – 1989): 36mm, steel </li>
                <li>
                  Vintage Explorer references 6350, 6150, 6610 (mid-1950s – mid-1960s); 36mm, steel{' '}
                </li>
              </ul>
              <br />
              <br />
              Gray &amp; Sons is an independent Rolex dealer, which means better inventory control
              and more price flexibility for customers. Every preowned Rolex Explorer watch for sale
              on Gray &amp; Sons’ website is in stock, ready to ship. Our decision-makers are ready
              to make deals on any in-stock secondhand Explorer men’s watch – or any other used
              Rolex watch available.
              <br />
              <br />
              Conversely, if you’ve wondered, “Where can I sell my used Rolex Explorer watch near
              me?” you can get paid the best cash offer by visiting{' '}
              <a href="https://sellusyourjewelry.com/">SellUsYourJewelry.com</a>
              <br />
              <br />
              <b>
                Gray &amp; Sons is the Best Source for Buying and Selling Pre-Owned Rolex Explorer
                Watches
              </b>
              <br />
              <br />
              <a href="/">Gray &amp; Sons</a> is proud to have served thousands of happy customers
              over the last 40 years at both our physical store, as well as our leading pre-owned
              watch e-commerce website. Many customers have nominated Gray &amp; Sons as the number
              one buyer and seller of pre-owned used Rolex Explorer watches. Chat with an expert
              today. Click <a href="/chat-with-rich">here to talk with a Rolex Specialist</a> today.
              <br />
              <br />
              The Gray &amp; Sons store (located adjacent to the Bal Harbour Shops in
              Surfside/Miami, open six days a week) serves the Miami, Miami Beach, Coral Gables,
              Coconut Grove, South Beach, Bal Harbour, Surfside, Sunny Isles, Ventura, Fort
              Lauderdale, Pinecrest, and Brickle areas. Whether in-store or through the website,
              Gray &amp; Sons watch experts are always ready to help you buy or sell secondhand
              Rolex Explorer watches. Plus, we also have a Gray &amp; Sons independent Rolex
              Explorer repair facility for any watch maintenance needs.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/catalogsearch/result?q=explorer">
                <button>SHOP ROLEX EXPLORER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK ROLEX EXPLORER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">EXPLORE MORE GREAT WATCH STORIES FROM GRAY &amp; SONS</h2>
        <RolexCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default RolexExplorer
